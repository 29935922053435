exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-1-js": () => import("./../../../src/pages/index1.js" /* webpackChunkName: "component---src-pages-index-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/whatWeDo.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-whats-happening-js": () => import("./../../../src/pages/whatsHappening.js" /* webpackChunkName: "component---src-pages-whats-happening-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/whoWeAre.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

